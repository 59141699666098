@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth;
  }
  
/* Apply the custom background color */
body {
  @apply bg-pageBackground text-white font-sans;
}

